<!--
#########################################################################################
######    Vista SVC51100  :  CONSULTA DE ENTRADAS Y SALIDAS                        ######
#########################################################################################
-->
<template>
    <div>
        <!-- Top Navigation (Genérico) -->
        <TNC10000></TNC10000>
  
        <!-- Transición -->
        <div class="igs-back-color8 igs-alto2">&nbsp;</div>

        <!-- Artículo, Lote, Operario, .... -->
        <div class="row igs-back-color8">
            <div class="col-md-1 igs-l5 igs-center igs-l-pad-20">
              <b>Desde</b><br/>
              <div class="igs-mt-5"><b>Hasta</b></div>
            </div>
            <div class="col-md-3 igs-l4 igs-left">
                  &nbsp;Artículo&nbsp;&nbsp;
                  <input type="text" v-model="ltFiltroDesdeArticulo" maxlength="6" size="8" class="igs-l3" @change="ltFiltroHastaArticulo = ltFiltroDesdeArticulo; ltDescripcionDesdeArticulo=''; ltDescripcionHastaArticulo=''" @input="(val) => (ltFiltroDesdeArticulo = ltFiltroDesdeArticulo.toUpperCase())" />&nbsp;
                  <span><b>{{ ltDescripcionDesdeArticulo }}</b></span>
                  <br/>
                  &nbsp;Artículo&nbsp;&nbsp;
                  <input type="text" v-model="ltFiltroHastaArticulo" maxlength="6" size="8" class="igs-l3 igs-mt-5" @input="(val) => (ltFiltroHastaArticulo = ltFiltroHastaArticulo.toUpperCase())" />&nbsp;
                  <span><b>{{ ltDescripcionHastaArticulo }}</b></span>
            </div>

            <div class="col-md-2 igs-l4 igs-left">
              Lote&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <input type="text" v-model="ltFiltroDesdeLote" maxlength="10" size="12" class="igs-l3" />
              <br/>
              Lote&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <input type="text" v-model="ltFiltroHastaLote" maxlength="10" size="12" class="igs-l3 igs-mt-5" />
            </div>

            <div class="col-md-2 igs-l4 igs-left">
              Caducidad&nbsp;
              <input type="text" v-model="ltFiltroDesdeCaducidad" maxlength="10" size="12" class="igs-l3" />
              <br/>
              Caducidad&nbsp;
              <input type="text" v-model="ltFiltroHastaCaducidad" maxlength="10" size="12" class="igs-l3 igs-mt-5" />
            </div>

            <div class="col-md-3 igs-l4 igs-left">
              <br/>
              <label class="checkbox">Filtros Adicionales (Artículo)</label>&nbsp;&nbsp;&nbsp;
              <input type="checkbox" v-model="lSWFiltrosAdicionalesArticulo" class="igs-l3 igs-mt-10">
            </div>

            <div class="col-md-1 igs-l6 igs-left">
                <span @click="lp98BusquedaDetalleMovimientos()">&nbsp;&nbsp;<Fa :icon="faSearch" /> Buscar</span>   
            </div>
        </div>

        <!-- Transición -->
        <div class="igs-back-color8 igs-alto3" v-if="lSWFiltrosAdicionalesArticulo">&nbsp;</div>

        <!-- Filtros Adicionales x Artículo -->
        <div class="row igs-back-color8" v-if="lSWFiltrosAdicionalesArticulo">
          <div class="col-md-1 igs-l5 igs-center igs-l-pad-20">
            <b>Desde</b><br/>
            <div class="igs-mt-5"><b>Hasta</b></div>
          </div>
          <div class="col-md-1 igs-l4 igs-left">
            &nbsp;Grupo&nbsp;
            <input type="text" v-model="ltFiltroDesdeTGP" maxlength="2" size="3" class="igs-l3" />&nbsp;
            <br/>
            &nbsp;Grupo&nbsp;
            <input type="text" v-model="ltFiltroHastaTGP" maxlength="2" size="3" class="igs-l3 igs-mt-5" />&nbsp;
          </div>
          <div class="col-md-2 igs-l4 igs-left">
            &nbsp;Fam. Estadística&nbsp;
            <input type="text" v-model="ltFiltroDesdeFES" maxlength="3" size="4" class="igs-l3" />&nbsp;
            <br/>
            &nbsp;Fam. Estadística&nbsp;
            <input type="text" v-model="ltFiltroHastaFES" maxlength="3" size="4" class="igs-l3 igs-mt-5" />&nbsp;
          </div>
          <div class="col-md-2 igs-l4 igs-left">
            &nbsp;Fam. Contable&nbsp;
            <input type="text" v-model="ltFiltroDesdeFCO" maxlength="3" size="4" class="igs-l3" />&nbsp;
            <br/>
            &nbsp;Fam. Contable&nbsp;
            <input type="text" v-model="ltFiltroHastaFCO" maxlength="3" size="4" class="igs-l3 igs-mt-5" />&nbsp;
          </div>
          <div class="col-md-2 igs-l4 igs-left">
            &nbsp;Proveedor&nbsp;
            <input type="text" v-model="ltFiltroDesdePRO" maxlength="6" size="7" class="igs-l3" />&nbsp;
            <br/>
            &nbsp;Proveedor&nbsp;
            <input type="text" v-model="ltFiltroHastaPRO" maxlength="6" size="7" class="igs-l3 igs-mt-5" />&nbsp;
          </div>
          <div class="col-md-2 igs-l4 igs-left">
            Fabricante&nbsp;
            <input type="text" v-model="ltFiltroDesdeFAB" maxlength="6" size="7" class="igs-l3" />&nbsp;
            <br/>
            Fabricante&nbsp;
            <input type="text" v-model="ltFiltroHastaFAB" maxlength="6" size="7" class="igs-l3 igs-mt-5" />&nbsp;
          </div>
          <div class="col-md-2 igs-l4 igs-left">
            &nbsp;Sección&nbsp;
            <input type="text" v-model="ltFiltroDesdeSCC" maxlength="6" size="7" class="igs-l3" />&nbsp;
            <br/>
            &nbsp;Sección&nbsp;
            <input type="text" v-model="ltFiltroHastaSCC" maxlength="6" size="7" class="igs-l3 igs-mt-5" />&nbsp;
          </div>
        </div>

        <!-- Transición -->
        <div class="igs-back-color8 igs-alto2">&nbsp;</div>

        <!-- Fechas, Caducidad, ... -->
        <div class="row igs-back-color8">
          <div class="col-md-1 igs-l5 igs-center igs-l-pad-20">
              <b>Desde</b><br/>
              <div class="igs-mt-5"><b>Hasta</b></div>
            </div>
            <div class="col-md-3 igs-l4 igs-center igs-l-pad-20">
                <div class="igs-mt-5">Fechas</div>
                <date-range-picker
                              ref="dp_RangoFechasDetalleMovimientos"
                              opens="left"
                              :append-to-body=true
                              :single-date-picker=false
                              :locale-data="dp1_localeData"
                              minDate="2023-01-01" maxDate="2035-12-31"
                              :timePicker=false
                              :timePicker24Hour=true
                              :showWeekNumbers=true
                              :showDropdowns=true
                              :autoApply=true
                              :ranges="dp1_defaultRanges"
                              :linkedCalendars=false
                              control-container-class="igs-btn-dh-fecha"
                              v-model="dp1_dateRange"
                              @update="lp30RangoFechasDetalleMovimientos"
                              >
                          <template v-slot:input="picker">
                            <div class="igs-color1 igs-l4 igs-mt-5">
                              <b>{{ picker.startDate | lf60FiltroFecha }} a {{ picker.endDate | lf60FiltroFecha }}</b>
                            </div>
                          </template>
                        </date-range-picker>
            </div>

            <div class="col-md-2 igs-l4 igs-left">
              Operario&nbsp;&nbsp;&nbsp;&nbsp;
              <input type="text" v-model="ltFiltroDesdeOperario" maxlength="6" size="8" class="igs-l3" />
              <br/>
              Operario&nbsp;&nbsp;&nbsp;&nbsp;
              <input type="text" v-model="ltFiltroHastaOperario" maxlength="6" size="8" class="igs-l3 igs-mt-5" />
            </div>

            <div class="col-md-2 igs-l4 igs-left">
              Dispositivo&nbsp;
              <input type="text" v-model="ltFiltroDesdeDispositivo" maxlength="6" size="8" class="igs-l3" />
              <br/>
              Dispositivo&nbsp;
              <input type="text" v-model="ltFiltroHastaDispositivo" maxlength="6" size="8" class="igs-l3 igs-mt-5" />
            </div>

            <div class="col-md-3 igs-l4 igs-left">              
              <label class="checkbox">Incluir Movimientos NO Confirmados</label>&nbsp;&nbsp;&nbsp;
              <input type="checkbox" v-model="lSWMovimientosNoConfirmados">
              <br/>
              Tipo de Movimiento&nbsp;
              <select class="igs-select-sm igs-mt-10" v-model="ltFiltroTipoMovimiento">
                <option value="">- Indiferente -</option>
                <option value="10">Entrada Compras</option>
                <option value="19">Ubicación Entradas</option>
                <option value="20">Cargas</option>
                <option value="21">Retornos</option>
                <option value="2C">Venta Almacén</option>
                <option value="30">Traspasos Almacén</option>
                <option value="40">Recuento (Tarea)</option>
                <option value="41">Recuento (Libre)</option>
                <option value="60">Reubicación Retornos</option>
                <option value="65">Reubicación (Libre)</option>
              </select>            
            </div>

            <div class="col-md-1 igs-l4 igs-center">
              <span @click="lp98ExportXLS()"  v-if="laRegistrosDMA.length > 0">&nbsp;&nbsp;<Fa :icon="faFileExcel" />&nbsp;Excel</span> <br/>
              <span v-if="laRegistrosDMA.length > 0" class="igs-l2 igs-color7">{{ laRegistrosDMA.length }} Movimientos</span>  
            </div>
        </div>

        <!-- Transición -->
        <div class="igs-back-color8 igs-alto2">&nbsp;</div>

        <!-- Resultados -->
        <div class="tableFixHead">

          <!-- HUD -->
          <div class="row">
            <div class="col-12 igs-ancho-100 igs-center" v-if="lbCargando">  
              <br/><br/>
              <h2 class="igs-color2">Buscando resultados ....</h2>
              <br/><br/>
              <vue-ellipse-progress :loading="true" />
            </div>
          </div>

          <!-- Resultados -->
          <table ref="Tabla511" v-if="!lbCargando">
            <thead>
              <tr class="igs-l3 igs-back-color2 igs-color0">
                <th scope="col" @click="lp50Sort(0)">Fecha y Hora<span  v-html="lf91ColumnaOrden(0)"></span></th>
                <th scope="col" @click="lp50Sort(1)">Terminal<span v-html="lf91ColumnaOrden(1)"></span></th>
                <th scope="col" @click="lp50Sort(2)">Operario<span  v-html="lf91ColumnaOrden(2)"></span></th>
                <th scope="col" @click="lp50Sort(3)">Ubicación<span  v-html="lf91ColumnaOrden(3)" /></th>
                <th scope="col" @click="lp50Sort(4)" v-if="ltFiltroDesdeArticulo != ltFiltroHastaArticulo">Artículo<span  v-html="lf91ColumnaOrden(4)" /></th>
                <th scope="col" @click="lp50Sort(5)">Lote<span  v-html="lf91ColumnaOrden(5)" /></th>
                <th scope="col" @click="lp50Sort(6)">Caducidad<span  v-html="lf91ColumnaOrden(6)" /></th>
                <th scope="col" @click="lp50Sort(7)">Cantidad<span  v-html="lf91ColumnaOrden(7)" /></th>
                <th scope="col" @click="lp50Sort(8)">Unidades<span  v-html="lf91ColumnaOrden(8)" /></th>
                <th scope="col" @click="lp50Sort(9)">Tipo Movimiento<span  v-html="lf91ColumnaOrden(9)" /></th>
                <th scope="col" @click="lp50Sort(10)">Datos Complementarios<span  v-html="lf91ColumnaOrden(10)" /></th>
              </tr>
            </thead>
            <tbody>
                <!-- Lista de Resultados -->
                <tr v-for="(dma, index) in laRegistrosDMA" :key="index" class="igs-l3">
                  <td scope="row">&nbsp;<nobr>{{ f70AMD2fDMA(dma.mdma[0].d083) }}</nobr>&nbsp;&nbsp;<nobr>{{ f70HMS2fHMS(dma.mdma[0].d084) }}</nobr></td>
                  <td>{{ dma.mdma[0].d081 }}</td>
                  <td class="igs-left">{{ dma.mdma[0].d082 }}</td>
                  <td class="igs-l2"><span  v-html="f93MaquetarUbicacion(dma.m001,5)"></span></td>
                  <td v-if="ltFiltroDesdeArticulo != ltFiltroHastaArticulo">{{ dma.m022 }} - {{ dma.a002 }}</td>
                  <td>{{ dma.mdma[0].d022 }}</td>
                  <td>{{ lf91FechaCaducidad(dma.mdma[0].d023) }}</td>
                  <td>{{ f91Cantidad(dma.mdma[0].d060) }}</td>
                  <td>{{ dma.mdma[0].d061 }}</td>
                  <td class="igs-l2">{{ f93DescripcionTipoMovimientoDMA(dma.mdma[0].d011,dma.mdma[0].d009,false) }}</td>
                  <td class="igs-l2"><span  v-html="f93DescripcionReferenciaTextoDMA(dma.mdma[0].d011,dma.mdma[0].d014,dma.mdma[0].d013,dma.mdma[0].d077)"></span></td>
                </tr>
            </tbody>
          </table>        
        </div>

        <BNC10000></BNC10000>
  
    </div>
  </template>
  
  <script>
  import igs_mixin from '@/igs-mixins/igs_mixin'
  import TNC10000  from '@/igs-navegacion/TNC10000'
  import BNC10000  from '@/igs-navegacion/BNC10000'
  
  import axios         from 'axios';
  import * as XLSX     from 'xlsx';
  
  import Fa from 'vue-fa'
  import { faSearch, faFileExcel, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons'

  //-- Rango de Fechas
  import DateRangePicker from 'vue2-daterange-picker'
  import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

  export default {
    mixins: [ igs_mixin ],
    components : { TNC10000, BNC10000, DateRangePicker, Fa },
  
    data() {
        //-- Valores Predeterminado
        let startDate = new Date();
        let endDate   = new Date();
        startDate.setDate(endDate.getDate() - 6);

        let today = new Date()
        today.setHours(0, 0, 0, 0)

        let yesterday = new Date()
        yesterday.setDate(today.getDate() - 1)
        yesterday.setHours(0, 0, 0, 0);

        var thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
        var thisMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0, 11, 59, 59, 999);

      return {
        //------------------------------------------------------------------------
        //-- Iconos
        //------------------------------------------------------------------------
        faSearch, faFileExcel, faSortUp, faSortDown,

         //------------------------------------------------------------------------
         //-- Variables Locales
         //------------------------------------------------------------------------
         ltFiltroDesdeArticulo : '',
         ltFiltroHastaArticulo : '',
         ltDescripcionDesdeArticulo : '',
         ltDescripcionHastaArticulo : '',
         ltFiltroDesdeOperario : '',
         ltFiltroHastaOperario : 'zzzzzz',
         ltFiltroDesdeDispositivo : '',
         ltFiltroHastaDispositivo : 'zzzzzz',
         ltFiltroDesdeLote : '',
         ltFiltroHastaLote : 'zzzzzzzzzz',
         ltFiltroDesdeCaducidad : '00-00-0000',
         ltFiltroHastaCaducidad : '31-12-9999',

         ltFiltroDesdeTGP : '00',
         ltFiltroHastaTGP : '99',
         ltFiltroDesdeFES : '',
         ltFiltroHastaFES : 'zzz',
         ltFiltroDesdeFCO : '',
         ltFiltroHastaFCO : 'zzz',
         ltFiltroDesdePRO : '',
         ltFiltroHastaPRO : 'zzzzzz',
         ltFiltroDesdeFAB : '',
         ltFiltroHastaFAB : 'zzzzzz',
         ltFiltroDesdeSCC : '',
         ltFiltroHastaSCC : 'zzzzzz',

         
         ltFiltroTipoMovimiento : '',

         lSWMovimientosNoConfirmados : false,
         lSWFiltrosAdicionalesArticulo : false,         

         ltFiltroDesdeFecha   : '00000000',      
         ltFiltroHastaFecha   : '99999999',

         dp1_dateRange: {startDate, endDate},
         dp1_localeData : {
            direction: 'ltr',
            format: 'dd-mm-yyyy',
            separator: ' a ',
            applyLabel: 'Confirmar',
            cancelLabel: 'Cancelar',
            weekLabel: 'W',
            customRangeLabel: 'Rango',
            daysOfWeek: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
            monthNames: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
            firstDay: 1
         },
         dp1_defaultRanges : {
            'Hoy': [today, today],
            'Ayer': [yesterday, yesterday],
            'Mes Actual': [thisMonthStart, thisMonthEnd],
            'Mes Anterior': [new Date(today.getFullYear(), today.getMonth() - 1, 1), new Date(today.getFullYear(), today.getMonth(), 0)],
            'Año Actual': [new Date(today.getFullYear(), 0, 1), new Date(today.getFullYear(), 11, 31)]
         },

         laRegistrosDMA : [],                     //-- Lista de Registros a mostrar

         lnColumnaOrden : 0,                      //-- Índice de la Columna por la cual Ordenar los Resultados
         lbOrdenAscendente : true,                //-- Ordenar los Resultados de forma ASCENDENTE (S/N)

         lbCargando : false                       //-- Cargando Datos (Sí/No)

      }
                
  
    },
  
    /**************************************************************/
    /****************   Métodos / Procedimientos   ****************/
    /**************************************************************/
    methods: {

        //............................................................................................
        //-- Conversión (Date) en (String) de la Fecha Inicial y Final para el Detalle de Movimientos
        //............................................................................................
        lp30RangoFechasDetalleMovimientos() {
            //-- Extraer Fecha INICIAL
            let startDate    = this.dp1_dateRange.startDate;
            let fechaInicial = this.f92Date2DTZ(startDate).substr(0,10).replace(/-/g,'');

            let endDate      = this.dp1_dateRange.endDate;
            let fechaFinal   = this.f92Date2DTZ(endDate).substr(0,10).replace(/-/g,'');

            //-- Retornar el valor calculado
            console.log(' fechaInicial = ('+fechaInicial+') // fechaFinal = ('+fechaFinal+')');

            this.ltFiltroDesdeFecha   = fechaInicial;
            this.ltFiltroHastaFecha   = fechaFinal;
        },

        //----------------------------------------------------------------------
        //-- Ordenar Resultados (por el ÍNDICE de la COLUMNA indicada)
        //----------------------------------------------------------------------
        lp50Sort(indice) {
          console.log(' lp50Sort ('+indice+')');

          //-- Misma Columna --> Conmutar Orden Ascendente/Descendente
          if (indice == this.lnColumnaOrden) {
            this.lbOrdenAscendente  = !this.lbOrdenAscendente;
          } else {
            //-- Actualizar Columna y Orden
            this.lnColumnaOrden     = indice;
            this.lbOrdenAscendente  = true;
          }

          //-- Ordenar Resultados
          this.lp60OrdenarResultados();

        },

        //----------------------------------------------------------------------
        //-- Ordenar los MOVIMIENTOS por el criterio seleccionado
        //----------------------------------------------------------------------
        lp60OrdenarResultados() {

            //-- Crear nueva lista con el Orden seleccionado
            let self = this;
            let listaOrdenada = this.laRegistrosDMA.sort(function(a, b){
              var nameA = '';
              var nameB = '';

              //-- Fecha y Hora
              if (self.lnColumnaOrden == 0) {
                  nameA=a.mdma[0].d083+a.mdma[0].d084;
                  nameB=b.mdma[0].d083+b.mdma[0].d084;

                  if ((nameA < nameB) && self.lbOrdenAscendente)  { return -1; }
                  if ((nameA > nameB) && self.lbOrdenAscendente)  { return  1; }

                  if ((nameA < nameB) && !self.lbOrdenAscendente) { return  1; }
                  if ((nameA > nameB) && !self.lbOrdenAscendente) { return -1; }

                  return 0; 
              }

              //-- Terminal
              if (self.lnColumnaOrden == 1) {
                  nameA=a.mdma[0].d081+a.mdma[0].d083+a.mdma[0].d084;
                  nameB=b.mdma[0].d081+b.mdma[0].d083+b.mdma[0].d084;

                  if ((nameA < nameB) && self.lbOrdenAscendente)  { return -1; }
                  if ((nameA > nameB) && self.lbOrdenAscendente)  { return  1; }

                  if ((nameA < nameB) && !self.lbOrdenAscendente) { return  1; }
                  if ((nameA > nameB) && !self.lbOrdenAscendente) { return -1; }

                  return 0; 
              }

              //-- Operario
              if (self.lnColumnaOrden == 2) {
                  nameA=a.mdma[0].d082+a.mdma[0].d083+a.mdma[0].d084;
                  nameB=b.mdma[0].d082+b.mdma[0].d083+b.mdma[0].d084;

                  if ((nameA < nameB) && self.lbOrdenAscendente)  { return -1; }
                  if ((nameA > nameB) && self.lbOrdenAscendente)  { return  1; }

                  if ((nameA < nameB) && !self.lbOrdenAscendente) { return  1; }
                  if ((nameA > nameB) && !self.lbOrdenAscendente) { return -1; }

                  return 0; 
              }

              //-- Ubicación
              if (self.lnColumnaOrden == 3) {
                  nameA=a.m001+a.mdma[0].d083+a.mdma[0].d084;
                  nameB=b.m001+b.mdma[0].d083+b.mdma[0].d084;

                  if ((nameA < nameB) && self.lbOrdenAscendente)  { return -1; }
                  if ((nameA > nameB) && self.lbOrdenAscendente)  { return  1; }

                  if ((nameA < nameB) && !self.lbOrdenAscendente) { return  1; }
                  if ((nameA > nameB) && !self.lbOrdenAscendente) { return -1; }

                  return 0; 
              }

              //-- Artículo
              if (self.lnColumnaOrden == 4) {
                  nameA=a.m022.padEnd(6)+a.mdma[0].d083+a.mdma[0].d084;
                  nameB=b.m022.padEnd(6)+b.mdma[0].d083+b.mdma[0].d084;

                  if ((nameA < nameB) && self.lbOrdenAscendente)  { return -1; }
                  if ((nameA > nameB) && self.lbOrdenAscendente)  { return  1; }

                  if ((nameA < nameB) && !self.lbOrdenAscendente) { return  1; }
                  if ((nameA > nameB) && !self.lbOrdenAscendente) { return -1; }

                  return 0; 
              }
              //-- Lote
              if (self.lnColumnaOrden == 5) {
                  nameA=a.mdma[0].d022+a.mdma[0].d083+a.mdma[0].d084;
                  nameB=b.mdma[0].d022+b.mdma[0].d083+b.mdma[0].d084;

                  if ((nameA < nameB) && self.lbOrdenAscendente)  { return -1; }
                  if ((nameA > nameB) && self.lbOrdenAscendente)  { return  1; }

                  if ((nameA < nameB) && !self.lbOrdenAscendente) { return  1; }
                  if ((nameA > nameB) && !self.lbOrdenAscendente) { return -1; }

                  return 0; 
              }

              //-- Caducidad
              if (self.lnColumnaOrden == 6) {
                  nameA=a.mdma[0].d023+a.mdma[0].d083+a.mdma[0].d084;
                  nameB=b.mdma[0].d023+b.mdma[0].d083+b.mdma[0].d084;

                  if ((nameA < nameB) && self.lbOrdenAscendente)  { return -1; }
                  if ((nameA > nameB) && self.lbOrdenAscendente)  { return  1; }

                  if ((nameA < nameB) && !self.lbOrdenAscendente) { return  1; }
                  if ((nameA > nameB) && !self.lbOrdenAscendente) { return -1; }

                  return 0; 
              }

              //-- Cantidad
              if (self.lnColumnaOrden == 7) {
                  nameA=self.f91PadLeft(Math.abs(a.mdma[0].d060),8)+a.mdma[0].d083+a.mdma[0].d084;
                  nameB=self.f91PadLeft(Math.abs(b.mdma[0].d060),8)+b.mdma[0].d083+b.mdma[0].d084;

                  if ((nameA < nameB) && self.lbOrdenAscendente)  { return -1; }
                  if ((nameA > nameB) && self.lbOrdenAscendente)  { return  1; }

                  if ((nameA < nameB) && !self.lbOrdenAscendente) { return  1; }
                  if ((nameA > nameB) && !self.lbOrdenAscendente) { return -1; }

                  return 0; 
              }

              //-- Unidades
              if (self.lnColumnaOrden == 8) {
                  nameA=self.f91PadLeft(Math.abs(a.mdma[0].d061),8)+a.mdma[0].d083+a.mdma[0].d084;
                  nameB=self.f91PadLeft(Math.abs(b.mdma[0].d061),8)+b.mdma[0].d083+b.mdma[0].d084;

                  if ((nameA < nameB) && self.lbOrdenAscendente)  { return -1; }
                  if ((nameA > nameB) && self.lbOrdenAscendente)  { return  1; }

                  if ((nameA < nameB) && !self.lbOrdenAscendente) { return  1; }
                  if ((nameA > nameB) && !self.lbOrdenAscendente) { return -1; }

                  return 0; 
              }

              //-- Tipo de Movimiento
              if (self.lnColumnaOrden == 9) {
                  nameA=a.mdma[0].d011+a.mdma[0].d083+a.mdma[0].d084;
                  nameB=b.mdma[0].d011+b.mdma[0].d083+b.mdma[0].d084;

                  if ((nameA < nameB) && self.lbOrdenAscendente)  { return -1; }
                  if ((nameA > nameB) && self.lbOrdenAscendente)  { return  1; }

                  if ((nameA < nameB) && !self.lbOrdenAscendente) { return  1; }
                  if ((nameA > nameB) && !self.lbOrdenAscendente) { return -1; }

                  return 0; 
              }
              //-- Documento / Observaciones
              if (self.lnColumnaOrden == 10) {
                  nameA=a.mdma[0].d014+a.mdma[0].d083+a.mdma[0].d084;
                  nameB=b.mdma[0].d014+b.mdma[0].d083+b.mdma[0].d084;

                  if ((nameA < nameB) && self.lbOrdenAscendente)  { return -1; }
                  if ((nameA > nameB) && self.lbOrdenAscendente)  { return  1; }

                  if ((nameA < nameB) && !self.lbOrdenAscendente) { return  1; }
                  if ((nameA > nameB) && !self.lbOrdenAscendente) { return -1; }

                  return 0; 
              }
              //-- Resto de Casos
              return 0;
            });

            //-- Reemplazar Lista de Resultados                                              
            this.laRegistrosDMA = listaOrdenada;

        },

        //----------------------------------------------------------------------
        //-- Búsqueda de MOVIMIENTOS 
        //----------------------------------------------------------------------
        lp98BusquedaDetalleMovimientos() {
          //-- Cargando Datos
          this.lbCargando = true;

          //-- Log
          this.p99Log(5,1,' -- lp98BusquedaDetalleMovimientos --');

          //-- Inicializaciones
          this.laRegistrosDMA                = [];

          //-- Concatenar Filtros a Aplicar
          let lTmpIncluirMNC = this.lSWMovimientosNoConfirmados ? 'S' : 'N';

          let ltFiltrosDMA = this.ltFiltroDesdeFecha        + '^' + this.ltFiltroHastaFecha       + '^' + 

                             this.ltFiltroDesdeArticulo     + '^' + this.ltFiltroHastaArticulo    + '^' + 
                             this.ltFiltroDesdeTGP          + '^' + this.ltFiltroHastaTGP         + '^' + 
                             this.ltFiltroDesdeFES          + '^' + this.ltFiltroHastaFES         + '^' + 
                             this.ltFiltroDesdeFCO          + '^' + this.ltFiltroHastaFCO         + '^' + 
                             this.ltFiltroDesdePRO          + '^' + this.ltFiltroHastaPRO         + '^' + 
                             this.ltFiltroDesdeFAB          + '^' + this.ltFiltroHastaFAB         + '^' + 
                             this.ltFiltroDesdeSCC          + '^' + this.ltFiltroHastaSCC         + '^' + 

                             '' + '^' + '' + '^' +

                             this.ltFiltroDesdeLote         + '^' + this.ltFiltroHastaLote        + '^' + 
                             this.ltFiltroDesdeCaducidad    + '^' + this.ltFiltroHastaCaducidad   + '^' + 
                             this.ltFiltroDesdeOperario     + '^' + this.ltFiltroHastaOperario    + '^' + 
                             this.ltFiltroDesdeDispositivo  + '^' + this.ltFiltroHastaDispositivo + '^' +

                             '' + '^' + '' + '^' +
                             '' + '^' + '' + '^' +

                             this.ltFiltroTipoMovimiento    + '^' +
                             lTmpIncluirMNC                 + '^';

          //-- Enviar Solicitud de Borrado al Servidor
          var URL = global.lnkServerAppURL + "&s="+this.$store.state.strIDSesion+"&u="+this.$store.state.strUsuario+"&d="+this.$store.state.strDistribuidor+"&a="+this.$store.state.strAlmacen+"&t=200&m=511&x=" + encodeURIComponent(ltFiltrosDMA);
          //this.p99Log(5,1,' (lp98BusquedaDetalleMovimientos) URL = '+URL);

          //-- Contactar con el Servidor del Distribuidor
          axios.get(URL, {responseEncoding: 'utf-8'})                         
          .then(respuesta => {

              //-- Extraer la respuesta recibida
              var datos = respuesta.data;

              //-- Determinar si ha habido EXITO ó ERROR
              var flg = global.lnkGetDataError;
              if (datos.flg) {  flg = datos.flg; }

              //-- Actualizar Resultados
              this.p99Log(1,1,'-- Respuesta (200511) --');
              this.p99Log(1,2,datos);

              if (flg == global.lnkGetDataOk)  {
                  this.p99Log(1,2,' ¡¡ EXITO !!');

                  //-- Actualizar Relación de Registros
                  this.laRegistrosDMA = datos.t200m511;

                  //-- Actualizar Descripción Artículo
                  if ((this.ltFiltroDesdeArticulo == this.ltFiltroHastaArticulo)) {
                    if ((this.laRegistrosDMA.length > 0) && (this.laRegistrosDMA[0].a002)) {
                      this.ltDescripcionDesdeArticulo = this.laRegistrosDMA[0].a002;
                      this.ltDescripcionHastaArticulo = this.laRegistrosDMA[0].a002;
                    } else {
                      this.ltDescripcionDesdeArticulo = '-- Sin Resultados --';
                      this.ltDescripcionHastaArticulo = '-- Sin resultados --';
                    }
                  } else {
                      this.ltDescripcionDesdeArticulo = '--- Rango  de  Artículos ---';
                      this.ltDescripcionHastaArticulo = '--- Rango  de  Artículos ---';
                  }

                  //-- Ordenar Resultados
                  this.lp60OrdenarResultados();

                  //-- Fin de la Carga
                  this.lbCargando = false;

              } else {
                  //-- Posible mensaje de error
                  if (datos.msg) { this.p99AnuncioIA1('2','7','ERROR', datos.msg,-1); } else { this.p99AnuncioIA1('2','7','ERROR', respuesta.data,-1); }

                  //-- Fin de la Carga
                  this.lbCargando = false;
              }

          })
          .catch(error => {
              //-- Mostrar el Mensaje de Error oportuno
              this.p99Log(1,1,'** ERROR **');
              this.p99Log(1,1,error);

              //-- Mensaje al Operador
              this.p99AnuncioIA1('2','7','ERROR', error,-1);

              //-- Fin de la Carga
              this.lbCargando = false;

          });

        },

        //----------------------------------------------------------------------
        //-- Maquetación de la FECHA DE CADUCIDAD
        //----------------------------------------------------------------------
        lf91FechaCaducidad(fecha) {

          //-- Valor "vacío" (cuando NO se recibe valor alguno)
          if ((fecha == '') || (fecha == '00000000')) {
            return '';
          }

          //-- Fecha Maquetada
          return this.f70AMD2fDMA(fecha);

        },

        //----------------------------------------------------------------------
        //-- Indicador de Columna y Orden aplicado
        //----------------------------------------------------------------------
        lf91ColumnaOrden(indice) {

          //-- Inicializar Resultado
          let textoHTML = '';

          //-- Aplicar Texto HTML ÚNICAMENTE sobre la columna por la cual está siendo ordenado el resultado
          if (indice == this.lnColumnaOrden) {
            if (this.lbOrdenAscendente) {
              textoHTML = '&nbsp;&nbsp;<i class="fa-solid fa-sort-up"/>';
            } else {
              textoHTML = '&nbsp;&nbsp;<i class="fa-solid fa-sort-down"/>';
            }
          }

          //-- Retornar Resultado
          return textoHTML;
          
        },

        //----------------------------------------------------------------------
        //-- Exportar a Excel el resultado mostrado en la Tabla
        //----------------------------------------------------------------------
        lp98ExportXLS() {
          const ws = XLSX.utils.table_to_sheet(this.$refs.Tabla511);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, 'Mvtos_Articulo_'+this.ltFiltroDesdeArticulo+'_'+this.ltFiltroHastaArticulo);
          XLSX.writeFile(wb, 'iAlmacen-511.xlsx');
        }        



    },
  
    /**************************************************************/
    /****************       Filtros / Formatos     ****************/
    /**************************************************************/
    filters: {
        //..............................................
        //--- Filtro para dar FORMATO a las FECHAS
        //..............................................
        lf60FiltroFecha: function (date) {

            let dw = date.toLocaleDateString('es-ES', { weekday: 'long' });
            dw = dw.charAt(0).toUpperCase() + dw.slice(1);

            //-- Fecha
            const d = date.getDate();
            const m = date.getMonth() + 1;
            //const y = date.getFullYear();
            const y = date.getFullYear();

            //-- Hora
            //const hora = date.getHours();
            //const minutos = date.getMinutes();

            //return dw + ' ' + (d < 10 ? '0' : '') + d + '-' + (m < 10 ? '0' : '') + m + '-' + y + ' ' + (hora < 10 ? '0' : '') + hora + ':' + (minutos < 10 ? '0' : '') + minutos;
            return dw + ' ' + (d < 10 ? '0' : '') + d + '-' + (m < 10 ? '0' : '') + m + '-' + y;
        }
    },


    //----------------------------
    //-- Datos Calculados
    //----------------------------
    computed: {
  
    },
  
    //-------------------------------
    //-- Una vez mostrada la vista
    //-------------------------------
    mounted() {
        //-- Actualizar Módulo "Vivo"
        this.$store.commit('p10StrModuleName', 'SVC51100');
  
        //-- Actualizar BreadCrumb 
        this.$store.commit('p10StrMenuBC', [
          {
            text: "5.1.1 Consulta de Entradas y Salidas",
            active: true
          }
       ]);

       //-- Replicar Desde/Hasta Fecha sobre los campos (texto) equivalentes
       this.lp30RangoFechasDetalleMovimientos();
    }
  
  }
  </script>
  
<style lang="scss">

  //........................................................................
  //-- Atributos para la Tabla de Resultados
  //........................................................................
  .tableFixHead           { overflow: auto; height: 74vh;           }
  .tableFixHead thead th  { position: sticky; top: 0; z-index: 1;   }

  table                   { border-collapse: collapse; width: 100%; }

  tr:nth-child(even)      { background-color: $bc8;                 }
  th, td                  { padding: 8px 16px;                      }
  th                      { background-color : $bc2;                }

</style>
  